
<template lang="pug">
div
  van-overlay(:show="loading")
    div(style="align-items: center;justify-content: center;")
      van-loading.wrapper(size="18" color="#0094ff")  加载中...
  //- p.title 报工系统
  van-form(label-width="5em" @submit="onSubmit" size='mini' colon)
    van-field(v-model="form.relation_code" label="订单编号" readonly)
    van-field(v-model="form.workshop_name" label="所属车间" readonly)
    van-field(v-model="form.part_name" label="名称" readonly)
    van-field(v-model="form.part_size" label="规格" readonly)
    van-field(v-model="form.product_num" label="产品数量" readonly)
    van-field(v-model="form.part_num" label="单件数量" readonly)
    van-field(v-model="form.total_num" label="总数量" readonly)
    van-field(v-model="form.worker_name" label="报工人" readonly  is-link @click="onPersonPicker" placeholder='请选择报工人' :rules="[{ required: true, message: '请选择报工人' }]") 
    van-field(v-model="form.process_name" label="报工工序" is-link readonly placeholder='请选择报工工序' @click="processPicker = true" :rules="[{ required: true, message: '请选择报工工序' }]") 
    van-field(v-model="form.num" label="报工数量" type="number" :rules="[{ required: true, validator: checkNumber, message: '请输入报工数量' }]") )
    van-field(v-model="form.remark" label="备注")
    div(style="margin: 20px 16px;" v-if='form.com_id_poi_companys && form.id_poi_eyas_sub_order_item_part && form.id_poi_eyas_workshop')
      van-button(round block type="info" native-type="submit" :disabled='commitState') 提交

  van-popup(v-model:show="processPicker" position="bottom" )
    van-picker(:columns="processOptions" @confirm="onProcessConfirm" @cancel="processPicker = false" show-toolbar)
  van-popup(v-model:show="personPicker" position="bottom" )
    van-search(v-model="searchkey" placeholder="请输入搜索名称或工号" :show-action='false' shape="round")
      //- template(#action)
        div(@click="onCustom") 指定
    van-picker(:columns="currentPersones" @confirm="onPersonConfirm" @cancel="personPicker = false" show-toolbar)
</template>

<script>
import PickerSearchSelect from '../../components/form/PickerSearchSelect'

import { Form } from 'vant';
import { Field } from 'vant';
Vue.use(Form);
Vue.use(Field);
export default {
  head () {
    return {
      title: '报工'
    }
  },
  layout: 'adminlte',
  components: {
    PickerSearchSelect
  },
  data() {
    return {
      loading: false,
      commitState: false,
      processPicker: false,
      personPicker: false,
      searchkey: '',
      form: {
        relation_code: '',
        workshop_name: '',
        product_name: '',
        product_num: '',
        part_name: '',
        part_num: '',
        part_size: '',
        com_id_poi_companys: '',
        id_poi_eyas_workshop: '',
        id_poi_eyas_sub_order: '',
        id_poi_eyas_sub_order_item: '',
        id_poi_eyas_sub_order_item_part: '',
        id_poi_eyas_workers: '',
        worker_name: '',
        id_poi_eyas_production_process: '',
        process_name: '',
        remark: '',
        num: '',
        org_poi_company_org: '',    //从属订单的部门
      },
      processOptions: [],
      originPersones: [
        // {
        //   name: '张三',
        //   code: 'a001',
        //   text: '张三/a001',
        // },
      ],
      currentPersones: []
    };
  },
  async mounted () {
    const queryObj = this.$route.query
    const { comid, workshopid, partid } = queryObj
    this.loading = true
    this.form.com_id_poi_companys = comid ? comid : 0,
    this.form.id_poi_eyas_workshop = workshopid ? workshopid : 0,
    this.form.id_poi_eyas_sub_order_item_part = partid,
    await this.initQrInfo()
    this.loading = false
  },
  watch: {
    searchkey(newV) {
      if (newV) {
        this.currentPersones = this.originPersones.filter(p => p.text.indexOf(newV) != -1)
      } else {
        this.currentPersones = [...this.originPersones]
      }
    },  
  },
  methods: {

    // 初始化配置项
    async initQrInfo() {
      try {
        let params = {
          com_id_poi_companys: this.form.com_id_poi_companys,
          id_poi_eyas_workshop : this.form.id_poi_eyas_workshop ,
          id_poi_eyas_sub_order_item_part: this.form.id_poi_eyas_sub_order_item_part
        }
        let res = await this.$api.GetWorkerLogQrInfo(params)
        if (res.data) {
          const { part, process, workers, workshop } = res.data
          if (!part || !process || !workers || !workshop) {
            this.$notify({
              message: '产品信息获取失败!',
              type: 'danger'
            })
          }
          if (part) {
            this.form.id_poi_eyas_sub_order = part.id_poi_eyas_sub_order
            this.form.id_poi_eyas_sub_order_item = part.id_poi_eyas_sub_order_item
            this.form.relation_code = part.relation_code
            this.form.product_name = part.product_name
            this.form.product_num = parseFloat(part.product_num)
            this.form.part_num = parseFloat(part.num)

            this.form.part_name = `${part.product_name} - ${part.name || ''}`
            this.form.part_size = `${parseFloat(part.length)}*${parseFloat(part.width)}*${parseFloat(part.height)}`
            this.form.total_num = parseFloat(part.num)* parseFloat(part.product_num)
          }

          if (workers) {
            this.originPersones = workers.map(p => {
              return {
                ...p,
                text: `${p.name}/${p.code}`
              }
            })
            this.currentPersones = [...this.originPersones]
          }

          if (process) {
            this.processOptions = process.map(p => {
              return {
                ...p,
                text: p.name
              }
            })
          }
          if (workshop) {
            this.form.workshop_name = workshop.name
            this.form.org_poi_company_org = workshop.rel_poi_company_org
          }
        }
      } catch (error) {
        this.$notify({
          message: '获取数据失败!',
          type: 'danger'
        })
      }
    },

    onPersonPicker() {
      this.searchkey = ''
      this.personPicker = true
    },

    onPersonConfirm(value) {
      this.form.worker_name = value ? value.text : ''
      this.form.id_poi_eyas_workers = value ? value.id : ''
      this.personPicker = false
    },

    onProcessConfirm(value) {
      this.form.process_name = value ? value.name : ''
      this.form.id_poi_eyas_production_process = value ? value.id : ''
      this.processPicker = false
    },

    onCustom() {
      this.form.id_poi_eyas_workers = ''
      this.form.worker_name = this.searchkey
      this.personPicker = false
    }, 

    checkNumber(value) {
      if (!value || parseFloat(value) == 0) {
        return false
      } 
      return true
    },

    async onSubmit() {
      let data = {
        ...this.form,
        num: parseFloat(this.form.num)
      }
      try {
        if (this.commitState) {
          return
        }
        this.commitState = true
        // 接口请求
        await this.$api.PostWorkerLog(data)
        this.$notify({
          message: '报工成功!',
          type: 'success'
        })
        setTimeout(()=> {
          window.close();
        },1000)
        // window.open('about:blank','_self').close() 
      } catch (e) {
        this.commitState = false
        this.$notify({
          message: '报工失败!',
          type: 'danger'
        })
      }
    }
  }
}
</script>
<style scoped>
.report-box {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 9999;
}
.report-body {
    max-width: 720px;
    padding: 20px 0;
    margin: 0 auto;
}
.title {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: #000;
    padding-bottom: 10px;
    margin-bottom: 0;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>